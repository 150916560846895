import React from 'react';
import {
  CircularProgress,
  Typography,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import {withStyles, styled, makeStyles} from '@material-ui/core/styles';
import {
  hexToRgb,
  whiteColor,
  grayColor,
} from '../assets/jss/material-dashboard-react';

const useStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const CircularScreenProgress = withStyles({
  root: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
})(CircularProgress);

export const WhiteCardTitle = styled(Typography)({
  color: whiteColor,
  marginTop: '0px',
  minHeight: 'auto',
  fontWeight: '300',
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  marginBottom: '3px',
  textDecoration: 'none',
  '& small': {
    color: grayColor[1],
    fontWeight: '400',
    lineHeight: '1',
  },
});

export const WhiteCardCategory = styled(Typography)({
  color: 'rgba(' + hexToRgb(whiteColor) + ',.62)',
  margin: '0',
  fontSize: '14px',
  marginTop: '0',
  marginBottom: '0',
});

export function CustomFormControl({children, ...rest}) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} {...rest}>
      {children}
    </FormControl>
  );
}

export function AlertDialog({open, setOpen, handleAgree}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record? This process cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={handleAgree} color="secondary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ScrollDialog({
  open,
  setOpen,
  children,
  handleOrder,
  title,
  maxWidth = 'sm',
  buttonText,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOrder} color="primary">
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
