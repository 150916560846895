import { getUser } from '../clients/auth-client';

async function bootstrapAppData() {
  const data = await getUser();
  if (!data) {
    return {};
  }

  const { data: response } = data;
  return response.data;
}

export { bootstrapAppData };
