import React from 'react';
import ReactDOM from 'react-dom';
import AppProvider from './context';
import App from './app.js';

import './assets/css/material-dashboard-react.css';

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root'),
);
