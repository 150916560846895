import React from 'react';
import * as authClient from '../clients/auth-client';
import { bootstrapAppData } from '../utils/bootstrap-app-data';
import { useAsync } from 'react-async';
import { CircularScreenProgress } from '../components/lib';

const AuthContext = React.createContext({});

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const { data = {}, isSettled, isPending, reload } = useAsync({
    promiseFn: bootstrapAppData,
  });

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <CircularScreenProgress thickness={5} size={50} />;
    }
  }

  const login = form => authClient.login(form).then(reload);
  const register = form => authClient.register(form).then(reload);
  const logout = () => authClient.logout().then(reload);

  return (
    <AuthContext.Provider
      value={{ login, register, data, logout }}
      {...props}
    />
  );
}
function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
