import axios from 'axios';

function client(endpoint, {body, ...customConfig} = {}) {
  const token = window.localStorage.getItem('__jadoo_crm_token__');
  const headers = {'Content-Type': 'application/json'};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'post' : 'get',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.data = JSON.stringify(body);
  }

  // const API_URL = 'http://103.69.149.242:5000/api/v1';
  // const API_URL = 'http://103.69.149.244:9080/api/v1';
  const API_URL = 'https://vm02.hidayahsmart.solutions/api/v1';
  return axios(`${API_URL}/${endpoint}`, config);
}

export default client;
