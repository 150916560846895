import React from 'react';
import {CircularScreenProgress} from './components/lib';
import {useUser} from './context/user-context';

const loadAuthenticatedApp = () => import('./authenticated-app');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnAuthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

export default function App() {
  const user = useUser();
  React.useEffect(() => {
    loadAuthenticatedApp();
  }, []);
  return (
    <React.Suspense
      fallback={<CircularScreenProgress thickness={5} size={50} />}
    >
      {Object.keys(user).length ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </React.Suspense>
  );
}
