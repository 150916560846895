import client from './api-client';
const localStorageKey = '__jadoo_crm_token__';

function handleUserResponse({data: response}) {
  window.localStorage.setItem(localStorageKey, response.data.token);
  return response.data;
}

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return client('auth/users/me').catch(error => {
    logout();
    return Promise.reject(error);
  });
}

function login({email, password}) {
  return client('auth/users/login', {body: {data: {email, password}}}).then(
    handleUserResponse,
  );
}

function register(data) {
  return client('auth/users/register', {
    body: {data},
  }).catch(err => {
    console.log('Registration error', err);
    return Promise.reject(err);
  });
}

function logout() {
  window.localStorage.removeItem(localStorageKey);
  return Promise.resolve();
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export function updatePassword(path, data) {
  return client(`${path}`, {body: {data}, method: 'PUT'}).catch(err => {
    console.log(err);
    return Promise.reject(err.response);
  });
}

export function resetPassword(path, data) {
  return client(path, {
    body: {data},
  }).catch(err => {
    console.log('Reset password error', err);
    return Promise.reject(err.response);
  });
}

export {login, register, logout, getToken, getUser};
